import Keycloak from 'keycloak-js'
import {App} from "vue";

const keycloak = new Keycloak({
    url: process.env.VUE_APP_AUTH_URI,
    realm: 'Ln-Cloud',
    clientId: 'files',
})
export default {
    install: (app: App) => {
        app.config.globalProperties.$keycloak = keycloak
    }
}