<template>
  <ln-container wrap lg="14" xl="12">
    <ln-row wrap>
      <ln-col space md="8" lg="8" xl="8">
        <ln-file-selector secondaryLight shadow height=60 border-radius=4 v-model="selectedFile" interaction-color><ln-icon fill="white" width="60" height="60">camera</ln-icon></ln-file-selector>
      </ln-col>
      <ln-col space md="8" lg="8" xl="8">
        <ln-text-box height=60 v-model="selectedUrl" :placeholder="'Bildadresse:'"></ln-text-box>
      </ln-col>
    </ln-row>
    <ln-row align="center">
      <ln-col space xs="8" sm="8" md="4" lg="4" xl="4">
        <ln-combobox width="100%" color-background="secondary-light" v-model="context" :items="['standard', 'fotosuniverse', 'muehlenblick', 'kochomat', 'schmidtkunz-shk', 'tech-eck']" />
      </ln-col>
      <ln-col space>
        <ln-button secondaryLight shadow height=60 width=100% border-radius=4 :disabled="!(!!selectedFile || !!selectedUrl)" :loading="loading" interaction-color @click="handleUpload()">Hochladen</ln-button>
      </ln-col>
    </ln-row>
  </ln-container>
  <ln-container lg="14" xl="12">
    <ln-row wrap>
      <ln-col space xs=8 sm=8 md=8 lg=4 xl=4 :key=image v-for="image in this.images" class="image-list-wrapper">
        <ln-image shadow height="10em" aspect-ratio="16/3" border-radius="4" class="image-list-image"
                 :src="apiUrl + `/image/${image}?cache=true&width=400&height=200&mode=crop&format=png`"
                 :lazy-src="apiUrl + `/image/${image}?cache=true&width=40&height=20&mode=crop&format=jpeg`"
                  @click="$router.push({name: 'DetailsPage', params: { imageId: image }})"></ln-image>
        <ln-icon secondaryLight hover border-radius="50%" height=40 width="40" @click="handleDelete(image)" class="image-list-icon">delete</ln-icon>
      </ln-col>
    </ln-row>
  </ln-container>
</template>

<script>
import {DELETE_IMAGE, FETCH_ALL_IMAGES, POST_NEW_IMAGE, UPDATE_CONTEXT} from "@/store";

export default {
  name: "LandingPage",
  data() {
    return {
      selectedFile: null,
      selectedUrl: null,
      loading: false
    }
  },
  computed: {
    images() {
      return this.$store.getters.images
    },
    apiUrl() {
      return process.env.VUE_APP_API_URI
    },
    context: {
      get() {
        return this.$store.getters.context
      },
      set(context) {
        this.$store.commit(UPDATE_CONTEXT, context)
      }
    }
  },
  mounted() {
    if (this.$store.getters.images.length <= 0) {
      this.$store.dispatch(FETCH_ALL_IMAGES, this.$store.getters.context)
    }
  },
  methods: {
    handleUpload() {
      this.loading = true
      this.$store.dispatch(POST_NEW_IMAGE, {
        imageUrl: this.selectedUrl ? this.selectedUrl : null,
        image: this.selectedFile ? this.selectedFile[0] : null,
        context: this.context
      }).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
      this.selectedFile = null
      this.selectedUrl = null
    },
    handleDelete(image) {
      this.$store.dispatch(DELETE_IMAGE, image)
    },
  },
  watch: {
    context() {
      this.$store.dispatch(FETCH_ALL_IMAGES, this.$store.getters.context)
    }
  }
}
</script>

<style scoped lang="scss">
.image-list-wrapper {
  position: relative;
  justify-content: flex-end !important;
  align-items: flex-start !important;
  .image-list-image {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    cursor: pointer;
  }
  .image-list-icon {
    position: absolute;
    margin: 3% 3% 0 0;
  }

}
</style>