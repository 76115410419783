<template>
  <ln-app>
    <router-view />
  </ln-app>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');
@font-face {
  font-family: "RubikDirtRegular";
  src: url(./fonts/RubikDirt-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothamMedium";
  src: url(./fonts/GothamMedium.eot) format("embedded-opentype"),
  url(./fonts/GothamMedium.ttf) format("truetype"),
  url(./fonts/GothamMedium.woff) format("woff"),
  url(./fonts/GothamMedium.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
