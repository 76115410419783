import { createStore } from 'vuex'
import app from "@/main";
import axios from "axios";

export const PERFORM_LOGIN = 'PERFORM_LOGIN'
export const UPDATE_AUTHENTICATED_STATUS = 'UPDATE_AUTHENTICATED_STATUS'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'

export const FETCH_ALL_IMAGES = 'FETCH_ALL_IMAGES'
export const UPDATE_IMAGE_LIST = 'FETCH_ALL_IMAGES'
export const POST_NEW_IMAGE = 'POST_NEW_IMAGE'
export const ADD_NEW_IMAGE_TO_IMAGES = 'ADD_NEW_IMAGE_TO_IMAGES'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const REMOVE_IMAGES_FROM_IMAGES = 'REMOVE_IMAGES_FROM_IMAGES'
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT'

export interface PostNewImage {
  imageUrl: string
  image: File,
  context: string
}

export default createStore({
  state: {
    userData: {
      profile: {
        email: null,
        email_verified: null,
        family_name: null,
        given_name: null,
        name: null,
        preferred_username: null,
        sub: null
      },
      authenticated: false,
    },
    images: {
      imageList: Array<string>(),
      context: 'standard'
    }
  },
  getters: {
    authenticated: state => state.userData.authenticated,
    images: state => state.images.imageList,
    context: state => state.images.context
  },
  mutations: {
    [UPDATE_USER_DATA](state, userData) {
      state.userData.profile = userData
    },
    [UPDATE_AUTHENTICATED_STATUS](state, authenticated) {
      state.userData.authenticated = authenticated
    },
    [UPDATE_IMAGE_LIST](state, images) {
      state.images.imageList = images
    },
    [ADD_NEW_IMAGE_TO_IMAGES](state, image) {
      state.images.imageList.push(image)
    },
    [REMOVE_IMAGES_FROM_IMAGES](state, image) {
      state.images.imageList.splice(state.images.imageList.indexOf(image), 1)
    },
    [UPDATE_CONTEXT](state, context) {
      state.images.context = context
    }
  },
  actions: {
    async [PERFORM_LOGIN](commit, url) {
      app.config.globalProperties.$keycloak.login({
        redirectUri: process.env.VUE_APP_HOST_URI + url.path
      })
    },
    async [FETCH_ALL_IMAGES]({ commit }, context) {
      axios
          .get(process.env.VUE_APP_API_URI + '/images/list',
              {
                headers: {
                  'Authorization': 'Bearer ' + app.config.globalProperties.$keycloak.token,
                  'Context': context
                }
              })
          .then(response => {
            commit(UPDATE_IMAGE_LIST, response.data)
          })
    },
    async [POST_NEW_IMAGE]({ commit }, payload: PostNewImage) {
      const formData = new FormData();
      payload.imageUrl ? formData.append('imageUrl', payload.imageUrl) : null
      payload.image ? formData.append('image', payload.image) : null
      payload.context ? formData.append('context', payload.context) : null
      await axios.post(process.env.VUE_APP_API_URI + '/images', formData,
          {
            headers: {
              'Authorization': 'Bearer ' + app.config.globalProperties.$keycloak.token,
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
            commit(ADD_NEW_IMAGE_TO_IMAGES, response.data)
      })
    },
    async [DELETE_IMAGE]({ commit }, image) {
      const response = await axios.delete(process.env.VUE_APP_API_URI + `/images/${image}`,
          {
            headers: {
              'Authorization': 'Bearer ' + app.config.globalProperties.$keycloak.token
            }
          }).then()
      if (response.status === 200) {
        commit(REMOVE_IMAGES_FROM_IMAGES, image)
      }
    }
  },
  modules: {
  }
})
