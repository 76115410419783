<template>
  <ln-container wrap lg="14" xl="12">
    <ln-row>
      <ln-col space>
        <ln-image as-img class="image" height="50vh" width="100%" aspect-ratio="16/5" shadow border-radius="4px" :src="getImageUrl()" :lazy-src="getLazyImageUrl()" />
      </ln-col>
    </ln-row>
  </ln-container>
  <ln-container wrap lg="10" xl="8">
    <ln-row wrap align="center">
      <ln-col space height="70" md="8" lg="8" xl="8">
        <ln-radio-group flow="row" align="center" v-model="imageShareSize">
          <ln-radio-button height=20 value="x-small">sehr klein</ln-radio-button>
          <ln-radio-button height=20 value="small">klein</ln-radio-button>
          <ln-radio-button checked height=20 value="medium">mittel</ln-radio-button>
          <ln-radio-button height=20 value="large">groß</ln-radio-button>
          <ln-radio-button height=20 value="x-large">sehr groß</ln-radio-button>
        </ln-radio-group>
      </ln-col>
      <ln-col space md="8" lg="8" xl="8">
        <ln-button secondaryLight height=60 width="100%" interaction-color border-radius=4 shadow @click="copyToClipboard()">Link teilen ...</ln-button>
      </ln-col>
    </ln-row>
    <ln-row>
      <ln-col space>
        <form style="width: 100%; margin-top: 10px;">
          <ln-text-box height="50" :placeholder="'URL zum Teilen'" v-model="shareUrl"></ln-text-box>
        </form>
      </ln-col>
    </ln-row>
  </ln-container>
</template>

<script>
export default {
  name: "DetailsPage",
  data() {
    return {
      imageId: this.$route.params.imageId,
      imageShareSize: null
    }
  },
  computed: {
    shareUrl() {
      return `${process.env.VUE_APP_SHARE_URI}/${this.imageShareSize}/${this.imageId}`
    }
  },
  methods: {
    getImageUrl() {
      return `${process.env.VUE_APP_API_URI}/image/${this.imageId}?format=jpeg`
    },
    getLazyImageUrl() {
      return `${process.env.VUE_APP_API_URI}/image/${this.imageId}?cache=true&width=300&height=150&format=jpeg`
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.shareUrl);
      // this.iosCopyToClipboard()
    },
    // iosCopyToClipboard() {
    //   const el = document.getElementById('copyToClipboard')
    //   const oldContentEditable = el.contentEditable,
    //       oldReadOnly = el.readOnly,
    //       range = document.createRange();
    //   el.contentEditable = true;
    //   el.readOnly = false;
    //   range.selectNodeContents(el);
    //   const s = window.getSelection();
    //   s.removeAllRanges();
    //   s.addRange(range);
    //   el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
    //   el.contentEditable = oldContentEditable;
    //   el.readOnly = oldReadOnly;
    //   document.execCommand('copy');
    // }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  > div {
    margin-top: 50px;
    > .image {
      width: 100%;
    }
  }
}
</style>