import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store, {UPDATE_AUTHENTICATED_STATUS, UPDATE_USER_DATA} from './store'
import { LnMaterialsPlugin } from '@ln-cloud/ln-materials'
import keycloakPlugin from "@/keycloak-plugin";

const app = createApp(App)
app.use(store)
app.use(router)
app.use(LnMaterialsPlugin, {
    colors: {
        primary: '#bcbcbc',
        primaryLight: '#eeeeee',
        primaryDark: '#8c8c8c',
        secondary: '#ef5350',
        secondaryLight: '#ff867c',
        secondaryDark: '#b61827',
    },
    textColor: 'black',
    fontFamily: '"RubikDirtRegular", "Montserrat", "GothamMedium", Noto, Sans, Arabic',
    fontWeight: 'normal'
})
app.use(keycloakPlugin)
app.config.globalProperties.$keycloak.onReady = function() {
    store.commit(UPDATE_AUTHENTICATED_STATUS, app.config.globalProperties.$keycloak.authenticated)
    app.config.globalProperties.$keycloak.loadUserInfo().then((userInfo: any) => {
        store.commit(UPDATE_USER_DATA, userInfo)
    })
}
app.config.globalProperties.$keycloak.onAuthSuccess = function() {
    store.commit(UPDATE_AUTHENTICATED_STATUS, app.config.globalProperties.$keycloak.authenticated)
    app.config.globalProperties.$keycloak.loadUserInfo().then((userInfo: any) => {
        store.commit(UPDATE_USER_DATA, userInfo)
    })
}
app.config.globalProperties.$keycloak.onTokenExpired = function() {
    app.config.globalProperties.$keycloak.updateToken(10).then()
}
app.config.globalProperties.$keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
    scope: 'openid profile email offline_access',
    responseType: 'code'
}).then(() => {
    app.mount('#app')
})
export default app
